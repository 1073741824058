




















































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { DailyReport, RowData } from './types';
import moment from 'moment';
import axios from 'axios';

interface RowDataReport extends RowData {
  time?: string;
}
export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    const date = moment().valueOf();
    return {
      display: false,
      reportTypes: [
        { id: 'daily', txt: '日報' },
        { id: 'monthly', txt: '月報' },
      ],
      columns: Array<any>(),
      statRows: Array<any>(),
      rows: Array<RowDataReport>(),
      form: {
        date,
        reportType: 'daily',
      },
    };
  },
  computed: {
    pickerType() {
      if (this.form.reportType === 'daily') return 'date';
      return 'month';
    },
  },
  methods: {
    async query() {
      this.display = true;
      const url = `/monitorReport/${this.form.reportType}/${this.form.date}`;
      const res = await axios.get(url);
      this.handleReport(res.data);
    },
    handleReport(report: DailyReport) {
      this.columns.splice(0, this.columns.length);
      if (this.form.reportType === 'daily') {
        this.columns.push({
          key: 'time',
          label: '時間',
          sortable: true,
        });
      } else {
        this.columns.push({
          key: 'time',
          label: '日期',
          sortable: true,
        });
      }
      for (let i = 0; i < report.columnNames.length; i++) {
        this.columns.push({
          key: `cellData[${i}].v`,
          label: `${report.columnNames[i]}`,
          sortable: true,
        });
      }
      for (const r of report.hourRows) {
        const row = r as RowDataReport;
        row.time =
          this.form.reportType === 'daily'
            ? moment(row.date).format('HH:mm')
            : moment(row.date).format('MM/DD');
      }
      this.rows = report.hourRows;
      this.statRows = report.statRows;
    },
  },
});
